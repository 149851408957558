import { useTranslation } from 'react-i18next';

import NoRefillsIcon from 'ui-kit/illustrations/no-refills/no-refills';

import './empty-state.style.scss';

const EmptyState = () => {
    const { t } = useTranslation();
    return (
        <div className="no-refills">
            <NoRefillsIcon />

            <div className="no-refills-content">
                <h4>{t('components.emptyState.noRefills.title')}</h4>
                <p>{t('components.emptyState.noRefills.description')}</p>
            </div>
        </div>
    );
};

export default EmptyState;
