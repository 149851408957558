import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { lowercaseAndCapitalize } from 'util/cart';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import { useState } from 'react';

import './prescription-tabs.style.scss';

type Dependents = {
    ePostPatientNum: string;
    familyMemberName: string;
    id: number;
};

type PrescriptionTabsProps = {
    isPrescriptionsAvailable: boolean;
    activeTab: string;
    activeDependentTab: string;
    dependents?: Dependents[];
    myEpostPatientNum?: string;
    onTabItemChange: (tab: string, dependent: string) => void;
    hasFilterTabs?: boolean;
    hasAutoRefillFlag: boolean;
};

const PrescriptionTabs: React.FC<PrescriptionTabsProps> = ({
    isPrescriptionsAvailable,
    activeTab,
    activeDependentTab,
    dependents = [],
    myEpostPatientNum,
    onTabItemChange,
    hasFilterTabs = true,
    hasAutoRefillFlag
}) => {
    const { t } = useTranslation();
    const [positionCarousel, setPositionCarousel] = useState(0);
    const orderByMe = (dependents: Dependents[], myEpostPatientNum: string) => {
        return myEpostPatientNum
            ? [...dependents].sort((a: Dependents, b: Dependents) => {
                  if (a.ePostPatientNum === myEpostPatientNum) return -1;
                  if (b.ePostPatientNum === myEpostPatientNum) return 1;

                  return a.id - b.id;
              })
            : dependents;
    };

    const prevAction = () => {
        setPositionCarousel(positionCarousel > 0 ? positionCarousel - 1 : 0);
    };

    const nextActionDesktop = () => {
        setPositionCarousel(positionCarousel < dependents.length - 3 ? positionCarousel + 1 : dependents.length - 3);
    };

    const nextActionMobile = () => {
        setPositionCarousel(positionCarousel < dependents.length - 2 ? positionCarousel + 1 : dependents.length - 2);
    };

    return (
        <div className="prescription-tabs">
            <ul
                className={classNames(
                    'tab-list d-none d-md-flex',
                    dependents.length > 4
                        ? 'tab-list-carousel'
                        : dependents.length > 0
                        ? 'tab-list-carousel-disabled'
                        : ''
                )}
                role="tablist"
            >
                {positionCarousel > 0 ? (
                    <li
                        key="tab-item-prev"
                        className="tab-item chevron-tab-icon"
                        role="tab"
                        aria-hidden="true"
                        onClick={prevAction}
                    >
                        <ChevronIcon direction={'left'} />
                    </li>
                ) : (
                    <></>
                )}
                {dependents.length > 0 ? (
                    orderByMe(dependents, myEpostPatientNum || '')
                        .slice(positionCarousel, positionCarousel + 4)
                        .map((dependent, index) => (
                            <li
                                key={`tab-item-${index}`}
                                className={classNames(
                                    'tab-item',
                                    activeDependentTab &&
                                        lowercaseAndCapitalize(activeDependentTab) === dependent.ePostPatientNum
                                        ? 'active'
                                        : 'inactive'
                                )}
                                role="tab"
                                aria-hidden="true"
                                onClick={() => onTabItemChange(activeTab, dependent.ePostPatientNum)}
                            >
                                {`${lowercaseAndCapitalize(dependent.familyMemberName)} ${
                                    dependent.ePostPatientNum === myEpostPatientNum ? '(You)' : ''
                                }`}
                            </li>
                        ))
                ) : (
                    <>
                        <li
                            key="tab-item-all"
                            className={classNames('tab-item', activeTab === 'all' ? 'active' : 'inactive')}
                            role="tab"
                            aria-hidden="true"
                            onClick={() => onTabItemChange('all', activeDependentTab)}
                        >
                            {t('components.prescriptionsList.mobile.tabs.allPrescriptions')}
                        </li>
                        {isPrescriptionsAvailable && (
                            <>
                                {hasAutoRefillFlag && (
                                    <li
                                        key="tab-item-auto-refills"
                                        className={classNames(
                                            'tab-item',
                                            activeTab === 'auto-refills' ? 'active' : 'inactive'
                                        )}
                                        role="tab"
                                        aria-hidden="true"
                                        onClick={() => onTabItemChange('auto-refills', activeDependentTab)}
                                    >
                                        {t('components.prescriptionsList.mobile.tabs.autoRefills')}
                                    </li>
                                )}
                                <li
                                    key="tab-item-expired"
                                    className={classNames('tab-item', activeTab === 'expired' ? 'active' : 'inactive')}
                                    role="tab"
                                    aria-hidden="true"
                                    onClick={() => onTabItemChange('expired', activeDependentTab)}
                                >
                                    {t('components.prescriptionsList.mobile.tabs.expired')}
                                </li>
                            </>
                        )}
                    </>
                )}
                {positionCarousel < dependents.length - 4 && dependents.length > 4 ? (
                    <li
                        key="tab-item-next"
                        className="tab-item chevron-tab-icon"
                        role="tab"
                        aria-hidden="true"
                        onClick={nextActionDesktop}
                    >
                        <ChevronIcon direction={'right'} />
                    </li>
                ) : (
                    <li className="last-tab"></li>
                )}
            </ul>
            <ul
                className={classNames(
                    'tab-list d-flex d-md-none',
                    dependents.length > 2
                        ? 'tab-list-carousel'
                        : dependents.length > 0
                        ? 'tab-list-carousel-disabled'
                        : ''
                )}
                role="tablist"
            >
                {positionCarousel > 0 ? (
                    <li
                        key="tab-item-prev-1"
                        className="tab-item chevron-tab-icon"
                        role="tab"
                        aria-hidden="true"
                        onClick={prevAction}
                    >
                        <ChevronIcon direction={'left'} />
                    </li>
                ) : (
                    <></>
                )}
                {dependents.length > 0 ? (
                    orderByMe(dependents, myEpostPatientNum || '')
                        .slice(positionCarousel, positionCarousel + 2)
                        .map((dependent, index) => (
                            <li
                                key={`tab-item-mobile-${index}`}
                                className={classNames(
                                    'tab-item',
                                    activeDependentTab &&
                                        lowercaseAndCapitalize(activeDependentTab) === dependent.ePostPatientNum
                                        ? 'active'
                                        : 'inactive'
                                )}
                                role="tab"
                                aria-hidden="true"
                                onClick={() => onTabItemChange(activeTab, dependent.ePostPatientNum)}
                            >
                                {`${lowercaseAndCapitalize(dependent.familyMemberName)} ${
                                    dependent.ePostPatientNum === myEpostPatientNum ? '(You)' : ''
                                }`}
                            </li>
                        ))
                ) : (
                    <>
                        <li
                            key="tab-item-all-1"
                            className={classNames('tab-item', activeTab === 'all' ? 'active' : 'inactive')}
                            role="tab"
                            aria-hidden="true"
                            onClick={() => onTabItemChange('all', '')}
                        >
                            {t('components.prescriptionsList.mobile.tabs.allPrescriptions')}
                        </li>
                        {isPrescriptionsAvailable && (
                            <>
                                {hasAutoRefillFlag && (
                                    <li
                                        key="tab-item-auto-refills-1"
                                        className={classNames(
                                            'tab-item',
                                            activeTab === 'auto-refills' ? 'active' : 'inactive'
                                        )}
                                        role="tab"
                                        aria-hidden="true"
                                        onClick={() => onTabItemChange('auto-refills', '')}
                                    >
                                        {t('components.prescriptionsList.mobile.tabs.autoRefills')}
                                    </li>
                                )}
                                <li
                                    key="tab-item-expired-1"
                                    className={classNames('tab-item', activeTab === 'expired' ? 'active' : 'inactive')}
                                    role="tab"
                                    aria-hidden="true"
                                    onClick={() => onTabItemChange('expired', '')}
                                >
                                    {t('components.prescriptionsList.mobile.tabs.expired')}
                                </li>
                            </>
                        )}
                    </>
                )}
                {positionCarousel < dependents.length - 2 && dependents.length > 2 ? (
                    <li
                        key="tab-item-next-1"
                        className="tab-item chevron-tab-icon"
                        role="tab"
                        aria-hidden="true"
                        onClick={nextActionMobile}
                    >
                        <ChevronIcon direction={'right'} />
                    </li>
                ) : (
                    <li key="tab-item-last" className="last-tab"></li>
                )}
            </ul>
            <div className="tab-slider" role="presentation" />
            {dependents.length > 0 && hasFilterTabs ? (
                <ul className="tab-buttons" role="tablist">
                    <li
                        key="tab-item-all-2"
                        role="tab"
                        aria-hidden="true"
                        className={classNames('tab-button', activeTab === 'all' ? 'active' : 'inactive')}
                        onClick={() => onTabItemChange('all', activeDependentTab)}
                    >
                        {t('components.prescriptionsList.mobile.tabs.allPrescriptions')}
                    </li>
                    {hasAutoRefillFlag && (
                        <li
                            key="tab-item-auto-refills-2"
                            role="tab"
                            aria-hidden="true"
                            className={classNames('tab-button', activeTab === 'auto-refills' ? 'active' : 'inactive')}
                            onClick={() => onTabItemChange('auto-refills', activeDependentTab)}
                        >
                            {t('components.prescriptionsList.mobile.tabs.autoRefills')}
                        </li>
                    )}
                    <li
                        key="tab-item-expired-2"
                        role="tab"
                        aria-hidden="true"
                        className={classNames('tab-button', activeTab === 'expired' ? 'active' : 'inactive')}
                        onClick={() => onTabItemChange('expired', activeDependentTab)}
                    >
                        {t('components.prescriptionsList.mobile.tabs.expired')}
                    </li>
                </ul>
            ) : (
                <></>
            )}
        </div>
    );
};

export default PrescriptionTabs;
