import { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

// UI Kit
import { Container, Button as ReactButton } from 'react-bootstrap';
import { SidebarColumnProps } from 'components/sidebar-column/sidebar-column.component';
import SidebarCloseIconProps from 'ui-kit/icons/sidebar-close-icon/sidebar-close-icon';
import Button from 'ui-kit/button/button';

interface SidebarColumnMobileProps extends SidebarColumnProps {}

const CartDrawer: FC<SidebarColumnMobileProps> = ({ children, cartTotalItems, cartLabel }: SidebarColumnProps) => {
    const isMounted = useRef<boolean>(true);

    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const [isLabelVisible, setIsLabelVisible] = useState(true);
    const [isCartButtonVisible, setIsCartButtonVisible] = useState(true);

    const toggleMobileMenuVisibility = () => {
        document.body.classList.toggle('scroll-lock');
        setIsMobileMenuVisible(!isMobileMenuVisible);
    };

    // Hotfix DRX-1982.
    // This implementation helps to prevent conflicting rules to
    // maintain the mobile opened after clicking on close button.
    const disableMobileMenuVisibility = () => {
        document.body.classList.remove('scroll-lock');
        setIsMobileMenuVisible(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                setIsLabelVisible(true);
            } else {
                setIsLabelVisible(false);
            }
        };

        if (isMounted.current) {
            window.addEventListener('scroll', handleScroll);
        }

        return () => {
            // Remove the event listener when the component is unmounted
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    // Hotfix DRX-1982.
    // This implementation prevents the screen to be scroll locked after
    // removing all prescriptions from cart on mobile devices.
    useEffect(() => {
        if (isMounted.current && (!cartTotalItems || (cartTotalItems && cartTotalItems < 1))) {
            disableMobileMenuVisibility();
            setIsCartButtonVisible(true);
        }
    }, [cartTotalItems]);

    return (
        <Container
            fluid
            className={classNames(
                'sidebar-column__drawer d-lg-none justify-content-center',
                `sidebar-column__drawer--${isMobileMenuVisible ? 'open' : 'closed'}`
            )}
        >
            {!!cartTotalItems && cartTotalItems > 0 && isCartButtonVisible && (
                <Button
                    itemCount={cartTotalItems}
                    cartIcon={true}
                    separator={true}
                    type="button"
                    label={isLabelVisible ? cartLabel! : ''}
                    aria-label={'Cart Button'}
                    onClick={toggleMobileMenuVisibility}
                    className={`d-lg-none sidebar-column__drawer-expanded-btn btn border-0 p-3 bg-cathams-blue no-min-width btn-primary d-flex align-items-center justify-content-between`}
                    style={isLabelVisible ? { maxWidth: '210px' } : ({ maxWidth: '112px' } as CSSProperties)}
                    chevron="up"
                />
            )}

            <div className="sidebar-column__drawer-content">
                <div className="sidebar-column__drawer-close">
                    <ReactButton
                        className="thumbsup bg-transparent p-0 border-0 no-min-width"
                        onClick={toggleMobileMenuVisibility}
                    >
                        <SidebarCloseIconProps style={{ height: '1rem', width: '1rem' }} />
                    </ReactButton>
                </div>
                {children}
            </div>
        </Container>
    );
};

export default CartDrawer;
