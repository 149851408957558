import { TFunction } from 'i18next';
import { EasyRefillRxResult } from 'types/easy-refill';
import { EasyRefillPrescriptionCardProps } from './types';
import { isShipStatusExpired } from 'util/prescription';
import moment from 'moment';

export function easyRefillPrescriptionPayloadToProps(
    payload: EasyRefillRxResult,
    t: TFunction<'translation'>,
    accountHasInsurance: boolean,
    isAddedToCart: boolean,
    onOrderRxClick?: () => void
): EasyRefillPrescriptionCardProps {
    onOrderRxClick = onOrderRxClick ? onOrderRxClick : () => {};
    let orderStatus: EasyRefillPrescriptionCardProps['orderStatus'] = 'NO_REFILLS';
    let sortOrder = 6;

    if (payload.webEligibilityStatus === 'ELIGIBLE' && payload.rxStatus === 'Profiled') {
        orderStatus = 'NEW_RX';
        sortOrder = 2;
    }
    if (payload.webEligibilityStatus === 'NOT_ELIGIBLE' && payload.itemInWorkflow === true) {
        orderStatus = 'ORDERED';
        sortOrder = 4;
    }
    if (
        payload.webEligibilityStatus === 'NOT_ELIGIBLE' &&
        payload.orderLineQueueStatus === 'SHIPPED' &&
        payload.itemInWorkflow === false
    ) {
        orderStatus = isShipStatusExpired(payload.lastFillDate) ? 'REFILL_TOO_SOON' : 'SHIPPED';
        sortOrder = isShipStatusExpired(payload.lastFillDate) ? 7 : 5;
    }
    if (
        payload.webEligibilityStatus === 'NOT_ELIGIBLE' &&
        payload.nextFillDate !== undefined &&
        payload.itemInWorkflow === false
    ) {
        orderStatus = isShipStatusExpired(payload.lastFillDate) ? 'REFILL_TOO_SOON' : 'SHIPPED';
        sortOrder = isShipStatusExpired(payload.lastFillDate) ? 7 : 5;
    }
    if (payload.webEligibilityStatus === 'ELIGIBLE' && payload.rxStatus !== 'Profiled') {
        orderStatus = 'REFILL_AVAILABLE';
        sortOrder = 3;
    }
    if (payload.inOrderCart) {
        orderStatus = 'IN_CART';
        sortOrder = 1;
    }
    if (!accountHasInsurance && !payload.inCashFormulary) {
        orderStatus = 'NOT_ON_FORMULARY';
        sortOrder = 8;
    }
    if (payload.webEligibilityStatus === 'PENDING') {
        orderStatus = 'PENDING';
        sortOrder = 9;
    }
    if (isAddedToCart) {
        orderStatus = 'IN_CART';
        sortOrder = 1;
    }

    // push next fill date for any RX other than New, Pending or Expired
    const showNextFillDate = (props: any) => {
        if (payload.nextFillDate) {
            const nextFillDateFormatted = moment(payload.nextFillDate).format('MM/DD/YYYY');
            const nextFillDateDiff = moment(payload.nextFillDate).diff(moment().format('MM/DD/YYYY'), 'days');

            props.statuses.push({
                primary: false,
                isRefillDue:
                    nextFillDateDiff < 1 &&
                    payload.webEligibilityStatus !== 'NOT_ELIGIBLE' &&
                    payload.itemInWorkflow !== true,
                status: <div>{nextFillDateFormatted}</div>,
                displayType: 'NEXT_REFILL'
            });
        }
    };

    const props = {
        fullPayload: payload, // for debugging
        prescriptionName: payload.dispensedProductName,
        orderStatus: orderStatus,
        sortOrder: sortOrder,
        inOrderCart: payload.inOrderCart,
        refillsLeft: Number(payload.fillsRemaining),
        rxNumber: payload.rxNumber,
        rxSeqNum: payload.rxSeqNum,
        isAccountInsured: accountHasInsurance,
        details: [
            {
                detail: t('components.prescriptionCard.rxNumberHeaderText', { number: payload.rxNumber })
            },
            {
                detail: t('components.prescriptionCard.quantity', {
                    fillQuantity: payload.fillQuantity
                })
            }
        ],
        statuses: [] as any[],
        ctas: [
            {
                label:
                    orderStatus !== 'IN_CART'
                        ? t('components.prescriptionCard.orderPrescription')
                        : t('components.prescriptionCard.inYourCart'),
                onClick: onOrderRxClick,
                disabled:
                    (orderStatus !== 'NEW_RX' && orderStatus !== 'REFILL_AVAILABLE' && orderStatus !== 'NO_REFILLS') ||
                    isAddedToCart === true
            }
        ]
    };
    if (orderStatus === 'IN_CART') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.inYourCart')}</div>,
            displayType: 'RX'
        });
    }
    if (orderStatus === 'NEW_RX') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderNewRxArrived')}</div>,
            displayType: 'RX'
        });
    }
    if (orderStatus === 'ORDERED') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderRxOrdered')}</div>,
            displayType: 'RX'
        });
        props.statuses.push({
            primary: false,
            status: <div>{t('components.prescriptionCard.orderInProgress')}</div>,
            displayType: 'ORDER'
        });
    } else if (orderStatus === 'SHIPPED') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderRxOrdered')}</div>,
            displayType: 'RX'
        });
        props.statuses.push({
            primary: false,
            status: <div>{t('components.prescriptionCard.orderShippedText')}</div>,
            displayType: 'ORDER'
        });
        showNextFillDate(props);
    } else if (orderStatus === 'REFILL_TOO_SOON') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderRefillTooSoon')}</div>,
            displayType: 'RX'
        });
        showNextFillDate(props);
    } else if (orderStatus === 'REFILL_AVAILABLE') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderRefillAva')}</div>,
            displayType: 'RX'
        });
        showNextFillDate(props);
    } else if (orderStatus === 'NO_REFILLS') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderExpired')}</div>,
            displayType: 'RX'
        });
        props.statuses.push({
            primary: false,
            status: <small>{t('components.prescriptionCard.orderPreAuthReq')}</small>,
            displayType: 'RX'
        });
    } else if (orderStatus === 'NOT_ON_FORMULARY') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.notOnFormulary')}</div>,
            displayType: 'RX'
        });
        showNextFillDate(props);
    } else if (orderStatus === 'PENDING') {
        props.statuses.push({
            primary: true,
            status: (
                <div>
                    {payload.rxNumber && payload.rxNumber !== 'N/A'
                        ? t('components.prescriptionCard.transferPending')
                        : t('components.prescriptionCard.requestPending')}
                </div>
            ),
            displayType: 'RX'
        });
    }

    return props;
}
