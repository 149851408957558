import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit
import Button from 'ui-kit/button/button';
import { ButtonProps } from 'ui-kit/button/button.props';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import EmptyAlertBox from 'components/medicine-cabinet-cart/empty-alert-box/empty-alert-box';
import ColumnSectionEditModeToggle from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

// Pages
import UpdateCartModalContent from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';
import UpdateProfileModalContent, {
    FailureUpdateProfileModalContent
} from 'pages/secure/profile/intra-page-items/_profile-update-modal.item';

// State
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    easyRefillGetPatientPaymentCardsRoutine,
    easyRefillUpdatePaymentCardsRoutine,
    easyRefillUpdatePaymentRoutine
} from 'state/easy-refill/easy-refill.routines';
import { easyRefillOrderSelector } from 'state/easy-refill/easy-refill.selectors';

// Types
import { PaymentCard } from 'types/easy-refill';
import { PaymentCardProps } from 'types/payment';

// Utils
import { noop } from 'util/function';
import { getCreditCardEnding } from 'util/payments';

// Hooks
import useWindowDimensions from 'hooks/useWindowDimensions';

// EasyRefill
import EasyRefillPaymentForm from './easy-refill-payment-form/easy-refill-payment-form';
import './easy-refill-payment-method.style.scss';
import NewPaymentItem, { PaymentItemProps } from './payment-item/payment-item.component';
import SelectedPayment from './selected-payment/selected-payment.component';

export interface PaymentMethodsProps extends Pick<PaymentItemProps, 'showSetDefaultLink' | 'showSelectCardRadioInput'> {
    paymentData: PaymentCard[];
    paymentRequiredMessage?: string;
    selectedCardSeqNum?: string;
    selectedPaymentMethod?: PaymentCard;
    onCardSelectionChange?: (newSelectedCard: PaymentCard) => void;
    onDefaultCardChanged?: (newDefaultCard: PaymentCard) => void;
}

interface UpdatePrimaryCreditCardOptions {
    showPrimaryPaymentUpdatedModal?: boolean;
}

const EasyRefillPaymentMethod: React.FC<PaymentMethodsProps> = (props) => {
    const {
        onDefaultCardChanged = noop,
        paymentData,
        paymentRequiredMessage,
        showSelectCardRadioInput = false,
        showSetDefaultLink = false,
        selectedPaymentMethod
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const hasPaymentData = paymentData.length > 0;
    const isInnerWidthViewportRef = React.useRef<boolean>(false);
    const order = useSelector(easyRefillOrderSelector);

    useEffect(() => {
        dispatch(easyRefillGetPatientPaymentCardsRoutine.trigger());
    }, []);

    const handleCardSelectionChange = React.useCallback<NonNullable<PaymentMethodsProps['onCardSelectionChange']>>(
        (newSelectedCard) => {
            if (!newSelectedCard) {
                return;
            }
            dispatch(
                easyRefillUpdatePaymentRoutine.trigger({
                    paymentCardSeqNum: newSelectedCard?.cardSeqNum,
                    onSuccess: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                className: 'prescription-modal',
                                bodyContent: (
                                    <BirdiModalContent
                                        icon={'success'}
                                        title={t('modals.paymentMethods.success.title')}
                                        body={t('modals.paymentMethods.success.description')}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.paymentMethods.success.cta'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'ReviewOrderUpdateCartEasyRefill'
                                    }
                                ]
                            })
                        );
                    },
                    onFailure: () => {
                        dispatch(
                            dispatch(
                                openModal({
                                    showClose: true,
                                    className: 'prescription-modal',
                                    bodyContent: <UpdateCartModalContent area={'ERROR'} />,
                                    ctas: [
                                        {
                                            label: t('modals.updateCart.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            dataGALocation: 'ReviewOrderUpdateCart'
                                        }
                                    ]
                                })
                            )
                        );
                    }
                })
            );
        },
        [dispatch, t]
    );

    const handleCardSelected = React.useCallback(
        (card: PaymentCard): PaymentCardProps['onSelectCardRadioInputChange'] =>
            () => {
                handleCardSelectionChange(card);
            },
        [handleCardSelectionChange]
    );

    const handleUpdatePrimaryCreditCard = React.useCallback(
        (newPrimaryCard: PaymentCard, opts: UpdatePrimaryCreditCardOptions = {}) => {
            const { showPrimaryPaymentUpdatedModal = true } = opts;

            if (!newPrimaryCard) {
                return;
            }

            dispatch(
                easyRefillUpdatePaymentCardsRoutine.trigger({
                    cardToUpdate: newPrimaryCard,
                    onSuccess: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: showPrimaryPaymentUpdatedModal ? (
                                    <BirdiModalContent
                                        icon={'success'}
                                        title={t('modals.updateProfile.title')}
                                        body={t('modals.primaryPaymentMethodUpdated.body', {
                                            paymentMethod: `<strong>${t('pages.profile.payment.cardTypeAndNum', {
                                                cardNumber: getCreditCardEnding(newPrimaryCard.secureCardNumber),
                                                cardType: newPrimaryCard?.cardType
                                            })}</strong>`
                                        })}
                                    />
                                ) : (
                                    <UpdateProfileModalContent area={t('modals.updateProfile.areas.payment')} />
                                ),
                                onClose: () => {
                                    dispatch(easyRefillGetPatientPaymentCardsRoutine.trigger());
                                },
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                            dispatch(easyRefillGetPatientPaymentCardsRoutine.trigger());
                                        }
                                    }
                                ]
                            })
                        );

                        onDefaultCardChanged(newPrimaryCard);
                    },
                    onFailure: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                type: 'danger',
                                size: 'lg',
                                headerContent: (
                                    <BirdiModalHeaderDanger icon="alert" headerText={t('modals.updateProfile.error')} />
                                ),
                                bodyContent: (
                                    <FailureUpdateProfileModalContent area={t('modals.updateProfile.areas.payment')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                            dispatch(easyRefillGetPatientPaymentCardsRoutine.trigger());
                                        }
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        },
        [dispatch, onDefaultCardChanged, t]
    );

    const handleAddNewPaymentClick = React.useCallback<NonNullable<ButtonProps['onClick']>>(() => {
        dispatch(
            openModal({
                showClose: true,
                contentClassName: 'prescription-add-payment-modal',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t('modals.addPaymentModal.title')}
                        body={
                            <EasyRefillPaymentForm
                                centerFormSubmit={true}
                                onCancel={() => {
                                    dispatch(closeModal({}));
                                }}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    }, [dispatch, t]);

    const handleSetDefaultClick = React.useCallback(
        (card: PaymentCard): PaymentCardProps['onSetDefaultClick'] =>
            () => {
                handleUpdatePrimaryCreditCard(card);
            },
        [handleUpdatePrimaryCreditCard]
    );

    useEffect(() => {
        if (width < 1200) {
            isInnerWidthViewportRef.current = true;
        } else {
            isInnerWidthViewportRef.current = false;
        }
    }, [width]);

    return (
        <ColumnSectionEditModeToggle
            className="easy-refill-payment-method"
            headerClassName="easy-refill-payment-method__header"
            title={t('components.medicineCabinetCart.paymentMethod.title')}
            isToggleDisabled={!hasPaymentData}
            editModeContent={
                <section className="easy-refill-payment-method__container">
                    {paymentData.length > 0 ? (
                        paymentData
                            .filter((filterCard) => filterCard.cardActive === true)
                            .map((card, index) => (
                                <React.Fragment key={`payment-card-${card.secureCardNumber}-${index}`}>
                                    <NewPaymentItem
                                        index={index}
                                        cardHolder={card.cardName}
                                        cardType={card.cardType}
                                        endingDigits={getCreditCardEnding(card.secureCardNumber)}
                                        expiryMonth={card.cardMonthNum}
                                        expiryYear={card.cardYear.slice(-2)}
                                        isDefaultCard={Boolean(card.defaultCard)}
                                        isSelectCardRadioInputChecked={card.cardSeqNum === order}
                                        key={`payment-card-${card.secureCardNumber}-${index}`}
                                        onSelectCardRadioInputChange={handleCardSelected(card)}
                                        onSetDefaultClick={handleSetDefaultClick(card)}
                                        showSelectCardRadioInput={showSelectCardRadioInput}
                                        showSetDefaultLink={showSetDefaultLink}
                                        isInnerWidthViewport={isInnerWidthViewportRef}
                                    />
                                </React.Fragment>
                            ))
                    ) : (
                        <div className="empty-section">
                            {paymentRequiredMessage && (
                                <>
                                    <EmptyAlertBox text={paymentRequiredMessage} />
                                    <Button
                                        plusIcon
                                        IconType="secondary"
                                        className="sm-full text-uppercase px-0"
                                        dataGAFormName="Payments"
                                        label={t('components.medicineCabinetCart.paymentMethod.addPaymentMethod')}
                                        onClick={handleAddNewPaymentClick}
                                        type="button"
                                        variant="text-blue"
                                    />
                                </>
                            )}
                        </div>
                    )}

                    {hasPaymentData && (
                        <Button
                            plusIcon
                            IconType="secondary"
                            className="sm-full text-uppercase px-0"
                            dataGAFormName="Payments"
                            label={t('components.medicineCabinetCart.paymentMethod.addPaymentMethod')}
                            onClick={handleAddNewPaymentClick}
                            type="button"
                            variant="text-blue"
                        />
                    )}
                </section>
            }
        >
            <>
                <SelectedPayment
                    isInnerWidthViewport={isInnerWidthViewportRef}
                    selectedPaymentMethod={selectedPaymentMethod}
                    creditCardsData={paymentData}
                />
                {!hasPaymentData && (
                    <Button
                        plusIcon
                        IconType="secondary"
                        className="sm-full text-uppercase px-0"
                        dataGAFormName="Payments"
                        label={t('components.medicineCabinetCart.paymentMethod.addPaymentMethod')}
                        onClick={handleAddNewPaymentClick}
                        type="button"
                        variant="text-blue"
                    />
                )}
            </>
        </ColumnSectionEditModeToggle>
    );
};

export default EasyRefillPaymentMethod;
