import { navigate } from 'gatsby';
import { useEffect, useState } from 'react';
// UI Kit & Components
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';
import ToastBox from 'ui-kit/toast-box/toast-box';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { drugSelector } from 'state/drug/drug.selectors';
import { easyRefillGetCartRoutine, easyRefillSubmitOrderRoutine } from 'state/easy-refill/easy-refill.routines';
// State
import {
    easyRefillCartItemsSelector,
    easyRefillOrderAddressSelector,
    easyRefillOrderPaymentCardSelector,
    easyRefillPlansSelector
} from 'state/easy-refill/easy-refill.selectors';

// Utils & Helpers
import { formatPrice } from 'schema/price.schema';

// Cart, Account & plans
import { CaregiverCartPayload, EasyRefillRxResult, ExtendedEasyRefillCartObjectPayload } from 'types/easy-refill';

import { processEasyRefillCart } from 'util/easy-refill';
import { getPhoneNumber } from 'util/globalVariables';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';

import { useEasyRefillHealthConditions } from 'hooks/useEasyRefillHealthConditions';

import './easy-refill-cart-total.style.scss';

interface ReviewNewCartTotalProps {
    isUnknownPrice: boolean;
    initialOrderPrice?: string;
    currentShippingPrice: number;
    isInsuranceAccount: boolean;
    cartObject?: CaregiverCartPayload[];
    prescriptionsObject: EasyRefillRxResult[];
}

export const EasyRefillCartTotal = ({
    isUnknownPrice,
    initialOrderPrice,
    currentShippingPrice,
    isInsuranceAccount,
    cartObject,
    prescriptionsObject
}: ReviewNewCartTotalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [extendedCartObject, setExtendedCartObject] = useState<ExtendedEasyRefillCartObjectPayload[]>();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const userAddress = useSelector(easyRefillOrderAddressSelector);
    const userPaymentCard = useSelector(easyRefillOrderPaymentCardSelector);
    const accountPlans = useSelector(easyRefillPlansSelector);
    const easyRefillCartItems = useSelector(easyRefillCartItemsSelector);

    const { userHasNotSubmittedAllergies, userHasNotSubmittedConditions } = useEasyRefillHealthConditions();
    const { drugDiscountPrices } = useSelector(drugSelector);

    const isOneClickOrderAvailable =
        !userHasNotSubmittedConditions && !userHasNotSubmittedAllergies && !!userPaymentCard && !!userAddress;

    const handleSubmitOrderClick = () => {
        setIsBusy(true);
        dispatch(
            easyRefillSubmitOrderRoutine({
                currentShippingPrice: Number(currentShippingPrice),
                lineItems: extendedCartObject?.flatMap((rx) => rx.extendedRefillRxs).filter((item) => !!item) || [],
                onSuccess: () => {
                    setIsBusy(false);
                    TrackCheckoutStep({
                        stepName: 'purchase',
                        step: '1',
                        carts: extendedCartObject,
                        prescriptions: prescriptionsObject,
                        t: t,
                        shippingCost: String(currentShippingPrice),
                        accountHasInsurance: isInsuranceAccount
                    });
                    navigate('/easy-refill/order-confirmation');
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            size: 'lg',
                            type: 'danger',
                            headerContent: (
                                <BirdiModalHeaderDanger headerText={t('modals.easyRefillFailure.title')} icon="alert" />
                            ),
                            bodyContent: (
                                <BirdiModalContent
                                    body={t(`pages.easyRefill.review.errors.orderError`, {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    })}
                                />
                            ),
                            ctas: [
                                {
                                    label: t('modals.easyRefillFailure.buttonText'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'EasyRefillOrderFailed'
                                }
                            ]
                        })
                    );
                    dispatch(easyRefillGetCartRoutine.trigger());
                    setIsBusy(false);
                }
            })
        );
    };

    useEffect(() => {
        async function getCartOrder() {
            if (cartObject && accountPlans && easyRefillCartItems) {
                const extendedCart = processEasyRefillCart(
                    cartObject,
                    isInsuranceAccount,
                    easyRefillCartItems,
                    drugDiscountPrices,
                    accountPlans
                );
                setExtendedCartObject(extendedCart);
            }
        }
        getCartOrder();
    }, [easyRefillCartItems, isInsuranceAccount, drugDiscountPrices, accountPlans, cartObject]);

    return (
        <Container fluid className="easy-refill-cart-total d-flex flex-column mt-4 mb-4 px-4">
            <Row>
                <Col sm={12} lg={12}>
                    <div className="cart-total d-flex justify-content-between">
                        <div>
                            {t('pages.cart.subTotal')}
                            {isUnknownPrice ? <sup>*</sup> : ''}:
                        </div>
                        <div>{formatPrice(initialOrderPrice)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="easy-refill-cart-total__shipping d-flex justify-content-between mt-3">
                        <div>{t('pages.cart.shipping')}</div>
                        <div>{formatPrice(currentShippingPrice)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-flex justify-content-between my-3 pt-4 border-top border-geyser">
                        <div className="easy-refill-cart-total__title">
                            {t('components.medicineCabinetCart.newCartTotal.total')}
                            {isUnknownPrice ? <sup>*</sup> : ''}:
                        </div>
                        <div className="easy-refill-cart-total__title">
                            {formatPrice(String(Number(initialOrderPrice) + Number(currentShippingPrice)))}
                        </div>
                    </div>
                </Col>
            </Row>
            {isUnknownPrice && isInsuranceAccount && (
                <Row className="mt-4">
                    <Col>
                        <small>
                            <sup>*</sup> {t('pages.cart.totalDisclaimer')}
                        </small>
                    </Col>
                </Row>
            )}
            <Row>
                <Col className="d-flex flex-column easy-refill-cart-total__container">
                    <ToastBox variant="info">
                        <div className="easy-refill-cart-total__note">
                            {t('components.medicineCabinetCart.newCartTotal.note')}
                        </div>
                    </ToastBox>

                    <Button
                        async
                        isBusy={isBusy}
                        disabled={!isOneClickOrderAvailable}
                        type="submit"
                        variant="primary"
                        label={t('components.medicineCabinetCart.newCartTotal.submit')}
                        onClick={handleSubmitOrderClick}
                    />
                    <Button
                        customFontSize="18"
                        type="button"
                        variant="text"
                        className="btn btn-text text-cerulean no-min-width"
                        label={t('components.medicineCabinetCart.newCartTotal.review')}
                        onClick={() => navigate('/easy-refill/review')}
                    />
                </Col>
            </Row>
            <br />
        </Container>
    );
};
