import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import CartDrawer from './cart-drawer.component';

import './sidebar-column.component.scss';
export interface SidebarColumnProps {
    children?: React.ReactNode;
    className?: string;
    isSidebarVisible?: boolean;
    isCartOnly?: boolean;
    cartTotalItems?: number;
    cartLabel?: string;
}

const Sidebar: FC<SidebarColumnProps> = ({
    children,
    className = '',
    isSidebarVisible,
    isCartOnly = false,
    cartTotalItems,
    cartLabel
}: SidebarColumnProps) => {
    return (
        <Row className={`sidebar-column${isCartOnly ? '__no-wrapper' : '__wrapper'} ${className}`}>
            <Col className={`${isSidebarVisible ? '' : 'd-none d-lg-block'} `}>
                <div className="sidebar-column">{children}</div>
            </Col>

            {isCartOnly && (
                <Col className="d-lg-none">
                    <CartDrawer cartLabel={cartLabel} cartTotalItems={cartTotalItems}>
                        {children}
                    </CartDrawer>
                </Col>
            )}
        </Row>
    );
};

export default Sidebar;
